html {
  scroll-behavior: smooth;
}
.title__line::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 90px;
  height: 3px;
  background: #ff76d8;
}

.title__line--width-65::after {
  width: 65px;
}

.navigation__link::after {
  content: " ";
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  width: 100%;
  height: 2px;
  background-color: #ff76d8;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.2s ease-out;
}

.navigation__link:hover::after {
  transform: scaleX(1)
}